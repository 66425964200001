import type { FunctionComponent } from 'react';
import React from 'react';
import type { Breadcrumb as BreadcrumbModel } from 'src/models';

import { getDataTestId, mergeClassNames } from '../../../utils/ReactUtils';
import Link from '../Link/Link';
import MetaRichSnippet from '../Meta/MetaRichSnippet';
import styles from './Breadcrumb.module.scss';

import { getBreadcrumbStructuredData } from 'src/utils/SeoUtils';

export type BreadcrumbProps = {
  breadcrumbItems: BreadcrumbModel[];
  className?: string;
  dataTestId?: string;
  hasRichSnippet?: boolean;
};

const renderBreadcrumbItem = (
  { title, targetUrl, query }: BreadcrumbModel,
  index: number,
) => (
  <li
    data-testid={`bread_crumb_item_${index}`}
    className={styles.breadcrumbItem}
    key={targetUrl}
  >
    {targetUrl ? (
      <Link className={styles.breadcrumbLink} href={targetUrl} query={query}>
        {title}
      </Link>
    ) : (
      <span className={styles.breadcrumbLink}>{title}</span>
    )}
  </li>
);

const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({
  breadcrumbItems,
  className,
  dataTestId,
  hasRichSnippet = true,
}) => (
  <>
    {hasRichSnippet ? (
      <MetaRichSnippet content={getBreadcrumbStructuredData(breadcrumbItems)} />
    ) : null}
    <ul
      className={mergeClassNames([styles.breadcrumbWrapper, className])}
      {...getDataTestId({ dataTestId })}
    >
      {breadcrumbItems.map(renderBreadcrumbItem)}
    </ul>
  </>
);

export default Breadcrumb;
