export const GEO_SLUG_PREFIX = 'garage-';
export const GEO_ITEMS_PER_PAGE = 10;

export const garageFilters = [
  {
    id: 'isMechanicalRepairer',
    label: 'Garage',
  },
  {
    id: 'isBodyRepairer',
    label: 'Carrosserie',
  },
  {
    id: 'hasBookingService',
    label: 'RDV en ligne',
  },
];

export const garageSortBy = [
  { key: 'distance:asc', value: 'distance' },
  { key: 'feedbackAverage:desc', value: 'avis' },
] as const;

export const infoTypes = {
  URL: 'URL',
  PHONE: 'PHONE',
  AVAILABILITY: 'AVAILABILITY',
};
