import type { FunctionComponent } from 'react';
import React from 'react';
import type { Garage } from 'src/models';
import { stepsEntries } from 'src/pages/home/components/constants';

import { useQuotationEngineContext } from 'src/components/layouts/PublicLayout/components/QuotationEngine/QuotationEngineContext';
import { Icon } from 'src/components/shared';
import Responsive from 'src/components/shared/Responsive/Responsive';
import { Col, Container, Row } from 'src/components/ui';
import Form from 'src/components/ui/Form';
import InputGroup from 'src/components/ui/InputGroup';

import style from './SearchEngineGarage.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

type SearchEngineGarageProps = {
  garage: Garage;
};

const SearchEngineGarage: FunctionComponent<SearchEngineGarageProps> = ({
  garage,
}) => {
  const { openQuotationEngine } = useQuotationEngineContext();

  const renderSteps: JSX.Element[] = stepsEntries.map((step, index) => {
    return (
      <div
        key={index}
        className={mergeClassNames([style.iconLabel, style.searchStepsItem])}
      >
        <Icon
          color={'#4A0B0F'}
          height={46}
          width={46}
          name={step.icon as never}
        />
        <span className={style.iconTxt}> {step.label} </span>
      </div>
    );
  });

  const locationOptions = {
    latitude: parseFloat(garage.location.split(',')[0].trim()),
    longitude: parseFloat(garage.location.split(',')[1].trim()),
  } as const;

  return (
    <Container fluid="lg">
      <div className={style.search} data-testid="search_engine_garage">
        <Row onClick={() => openQuotationEngine({ garage })}>
          <Col md={4}>
            <h3 className={style.searchTitle}>
              <Responsive is="desktop">
                Devis et rendez-vous facile et immédiat dans les garages ad
              </Responsive>
              <Responsive is="mobile">
                Devis gratuit et rendez-vous immédiat
              </Responsive>
            </h3>
          </Col>
          <Col md={8}>
            <InputGroup
              className={style.searchInputGroup}
              onClick={() => {
                openQuotationEngine({
                  garage,
                  ...locationOptions,
                });
              }}
            >
              <Form.Control
                type="text"
                name="GarageSearchServiceInput"
                id="GarageSearchServiceInput"
                className={style.searchInputGroupText}
                placeholder="Recherchez des prestations"
                aria-label="ad Services"
                aria-describedby="ad Services"
                data-testid="search_engine_input"
              />
              <Responsive is="desktop" cloneChild>
                <button
                  className={style.searchInputGroupButton}
                  data-testid="desktop_search_engine_button"
                >
                  <Icon
                    width={25}
                    height={25}
                    color={'white'}
                    name="recherche"
                    className={style.searchInputGroupIcon}
                  />
                  <span>Voir les prix</span>
                  <Icon
                    width={20}
                    height={20}
                    color={'white'}
                    name="fleche-d"
                    className={style.searchInputGroupIcon}
                  />
                </button>
              </Responsive>
              <Responsive is="mobile" cloneChild>
                <button
                  className={style.searchInputGroupButton}
                  data-testid="mobile_search_engine_button"
                >
                  <Icon
                    width={25}
                    height={25}
                    color={'white'}
                    name="recherche"
                    className={style.searchInputGroupIcon}
                  />
                </button>
              </Responsive>
            </InputGroup>
          </Col>
        </Row>
        <Responsive is="desktop">
          <div className={style.searchSteps}>{renderSteps}</div>
        </Responsive>
      </div>
    </Container>
  );
};

export default SearchEngineGarage;
