import type { FunctionComponent } from 'react';
import React from 'react';
import TagManager from 'react-gtm-module';
import type { Vehicle } from 'src/models';
import Carousel from 'src/packages/Carousel';
import VehicleVignette from 'src/pages/annonces-vehicules/components/vehicleVignette/VehicleVignette';

import { ButtonPrimary, Link, Svg } from 'src/components/shared';
import Responsive from 'src/components/shared/Responsive/Responsive';
import { Col, Row } from 'src/components/ui';

import style from './VehiclesAdGarage.module.scss';

import { usePageType } from 'src/utils/ReactUtils';

export type VehiclesAdGarageProps = {
  vehicles: Vehicle[];
  idBaseNetwork?: string;
};

const VehiclesAdGarage: FunctionComponent<VehiclesAdGarageProps> = ({
  idBaseNetwork,
  vehicles,
}) => {
  const pageType = usePageType();

  const gtmClickEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'module VO',
        eventAction: 'clic sur Voir tous nos vehicules',
        eventLabel: '/',
        pageType,
      },
    });
  };

  const renderVignette = (isMobile = false) => (
    <>
      {isMobile ? (
        <Carousel isStretched hasRightEdgeFade hasPagination={false}>
          {vehicles.slice(0, 3).map((vehicle, index) => (
            <Col key={index} className={style.vignetteContainer}>
              <VehicleVignette
                vehicle={vehicle}
                className={style.vignette}
                idBaseNetwork={idBaseNetwork ?? vehicle.idNetworkBase}
              />
            </Col>
          ))}
        </Carousel>
      ) : (
        <>
          <Responsive is="mediumDesktop">
            <div className={`d-flex flex-row`}>
              {vehicles.slice(0, 2).map((vehicle, index) => (
                <Col key={index} md={6} className={style.vignetteContainer}>
                  <VehicleVignette
                    vehicle={vehicle}
                    className={style.vignette}
                    idBaseNetwork={idBaseNetwork ?? vehicle.idNetworkBase}
                  />
                </Col>
              ))}
            </div>
          </Responsive>
          <Responsive isOrLarger="largeDesktop">
            <div className={`d-flex flex-row`}>
              {vehicles.slice(0, 3).map((vehicle, index) => (
                <Col key={index} md={4} className={style.vignetteContainer}>
                  <VehicleVignette
                    vehicle={vehicle}
                    className={style.vignette}
                    idBaseNetwork={idBaseNetwork ?? vehicle.idNetworkBase}
                  />
                </Col>
              ))}
            </div>
          </Responsive>
        </>
      )}
    </>
  );

  const shouldPerformSEOTrick = idBaseNetwork;

  return (
    <div className={style.vehicles}>
      <Responsive is="desktop">
        <Row className={style.box}>
          <Col md={8}>
            {renderVignette()}
            <p className={style.mentionLegal}>
              * Un crédit vous engage et doit être remboursé. Vérifiez vos
              capacités de remboursement avant de vous engager.
            </p>
          </Col>
          <Col md={4}>
            <span className={style.headText}> NOUVEAU ! </span>
            <div className={style.title}>
              <strong>VÉHICULES D’OCCASION CERTIFIÉS AD </strong> LIVRÉS DANS
              NOS GARAGES !
            </div>
            <div className={style.text}>
              Profitez de notre expertise pour trouver le véhicule d’occasion
              qui vous correspond.
              <span className={style.redText}> AD c’est sûr ! </span>
            </div>
            <div className={`d-flex flex-wrap`}>
              <span
                className={`d-flex flex-column align-items-center ${style.vehicleBadge}`}
              >
                <Svg
                  src={`/icons/pages/annonces-vehicules/ad_occasion_certifiee.svg`}
                  height={48}
                  width={30}
                />
                <span className={style.vehicleBadgeText}>
                  Véhicule certifié par nos garagistes
                </span>
              </span>
              <span
                className={`d-flex flex-column align-items-center ${style.vehicleBadge}`}
              >
                <Svg
                  src={`/icons/pages/annonces-vehicules/ad_occasion_pepite.svg`}
                  height={60}
                  width={40}
                />
                <span className={style.vehicleBadgeText}>
                  Véhicule récent, garantie étendue et faible km
                </span>
              </span>
              <Link
                href="/annonces-vehicules"
                query={
                  !shouldPerformSEOTrick
                    ? { id_network_base: idBaseNetwork }
                    : undefined
                }
                onClick={
                  shouldPerformSEOTrick
                    ? (e) => {
                        e.preventDefault();

                        window.location.href =
                          '/annonces-vehicules?id_network_base=' +
                          idBaseNetwork;
                      }
                    : undefined
                }
                title="occasion"
              >
                <ButtonPrimary
                  rightIcon={'fleche-d'}
                  className={style.btnAll}
                  data-testid="garage_all_vehicles"
                  onClick={gtmClickEvent}
                >
                  Voir tous nos véhicules
                </ButtonPrimary>
              </Link>
            </div>
          </Col>
        </Row>
      </Responsive>

      <Responsive is="mobile">
        <div className={style.boxMobile}>
          <div className={style.vehicleBadgeMobile}>
            <Svg
              src={`/icons/pages/annonces-vehicules/ad_occasion_certifiee.svg`}
              height={100}
              width={50}
              className={style.iconBadgeMobile}
            />
            <span className={style.titleMobile}>
              véhicules d’occasion certifiés AD
            </span>
            <span className={style.titleHeadMobile}>
              disponibles dans votre garage !
            </span>
          </div>

          {renderVignette(true)}

          <Col className={style.btnWrapper}>
            <Link
              href="/annonces-vehicules"
              query={
                !shouldPerformSEOTrick
                  ? { id_network_base: idBaseNetwork }
                  : undefined
              }
              onClick={
                shouldPerformSEOTrick
                  ? (e) => {
                      e.preventDefault();

                      window.location.href =
                        '/annonces-vehicules?id_network_base=' + idBaseNetwork;
                    }
                  : undefined
              }
              title="occasion"
            >
              <ButtonPrimary
                rightIcon={'fleche-d'}
                className={style.btnAllMobile}
                data-testid="garage_all_vehicles"
                onClick={gtmClickEvent}
              >
                Voir tous nos véhicules
              </ButtonPrimary>
            </Link>
          </Col>
        </div>
      </Responsive>
    </div>
  );
};

export default VehiclesAdGarage;
