import dynamic from 'next/dynamic';
import type { FunctionComponent } from 'react';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import type { ILightBoxProps } from 'react-image-lightbox';

const LightBoxDynamic = dynamic(() => import('./components/LightBoxDynamic'));

const LightBox: FunctionComponent<ILightBoxProps> = (props) => {
  return <LightBoxDynamic {...props} />;
};

export default LightBox;
