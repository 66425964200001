import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import type { Garage } from 'src/models';

import CMSContent from 'src/components/shared/CMSContent/CMSContent';

import {
  ButtonTertiary,
  Icon,
  ShowMoreText,
} from '../../../../../../components/shared';
import LightBox from '../../../../../../components/shared/LightBox/LightBox';
import style from './GarageInfosMobile.module.scss';

import { garageTitleWording } from 'src/utils/StringUtils';

export type GarageInfosMobileProps = {
  garage: Garage;
};

const GarageInfosMobile: FunctionComponent<GarageInfosMobileProps> = ({
  garage,
}) => {
  const [isLightboxOpen, setIsLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const getImagesLength = () => garage?.homepagePictures.length;
  const getNextIndex = () => (photoIndex + 1) % getImagesLength();
  const getPrevIndex = () =>
    (photoIndex + garage.homepagePictures.length - 1) % getImagesLength();

  return (
    <div className={style.garageInfosMobile}>
      <h2 className={style.garageName}>
        Découvrez votre {garageTitleWording(garage.typeEnseigne)} à{' '}
        {garage.city} ({garage.zipCode})
      </h2>
      <ShowMoreText heightLimit={120} className={style.garagePresentation}>
        <p className={style.text}>{garage.companyPresentation}</p>
        {garage.pageContent ? (
          <CMSContent content={garage.pageContent} />
        ) : null}
      </ShowMoreText>

      <ButtonTertiary
        className={style.garageBtn}
        color={'Brown'}
        onClick={() => setIsLightBoxOpen(true)}
      >
        <Icon
          className={style.garageBtnIcon}
          height={18}
          width={18}
          name="photos"
        />
        Voir nos photos
      </ButtonTertiary>
      {isLightboxOpen && (
        <LightBox
          wrapperClassName={style.lightboxWrapper}
          mainSrc={garage?.homepagePictures[photoIndex]}
          onCloseRequest={() => setIsLightBoxOpen(false)}
          nextSrc={garage?.homepagePictures[getNextIndex()]}
          prevSrc={garage?.homepagePictures[getPrevIndex()]}
          onMovePrevRequest={() => setPhotoIndex(getPrevIndex())}
          onMoveNextRequest={() => setPhotoIndex(getNextIndex())}
        />
      )}
    </div>
  );
};

export default GarageInfosMobile;
