import React from 'react';
import type { FunctionComponent, RefObject } from 'react';

import CMSContent from 'src/components/shared/CMSContent/CMSContent';
import NetworkAd from 'src/components/shared/Network/NetworkAd';

import styles from './GaragePresentation.module.scss';

import { garageTitleWording } from 'src/utils/StringUtils';

export type GaragePresentationProps = {
  companyPresentation: string;
  pageContent?: string;
  scrollPointRef: RefObject<HTMLDivElement>;
  typeEnseigne: string;
  city: string;
  zipCode: string;
};

const GaragePresentation: FunctionComponent<GaragePresentationProps> = ({
  companyPresentation,
  pageContent,
  scrollPointRef,
  typeEnseigne,
  city,
  zipCode,
}) => {
  return (
    <>
      <div className={styles.garageSectionRef} ref={scrollPointRef}></div>
      <NetworkAd
        title={`Découvrez votre ${garageTitleWording(
          typeEnseigne,
        )} à ${city} (${zipCode}`}
      >
        <div className={styles.garageSectionRef} ref={scrollPointRef}></div>
        <p className={styles.text}>{companyPresentation}</p>
        {pageContent ? <CMSContent content={pageContent} /> : null}
      </NetworkAd>
    </>
  );
};

export default GaragePresentation;
