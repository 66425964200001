import type { Breadcrumb } from 'src/models';

export type SeoTextTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

export const getBreadcrumbStructuredData = (breadcrumb: Breadcrumb[]) => {
  const itemListElement = breadcrumb.map((item, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: item.title,
    ...(item.targetUrl && {
      item: `${process.env.NEXT_PUBLIC_URL}${item.targetUrl}`,
    }),
  }));

  return {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };
};
