import type { FunctionComponent } from 'react';
import React from 'react';

import { Link } from '../index';
import style from './LinkSeeMore.module.scss';

import { getDataTestId, mergeClassNames } from 'src/utils/ReactUtils';

export type LinkSeeMoreProps = {
  text: string;
  maxCharLength: number;
  url?: string;
  className?: string;
  dataTestId?: string;
  onClick?: () => void;
};

const LinkSeeMore: FunctionComponent<LinkSeeMoreProps> = ({
  text,
  maxCharLength,
  url,
  className,
  dataTestId,
  onClick,
}) => {
  const reducedString = `${text?.substr(0, maxCharLength)}...`;

  return (
    <p
      className={mergeClassNames([style.LinkSeeMore, className])}
      {...getDataTestId({ dataTestId })}
    >
      {reducedString}

      {url && (
        <Link href={url} onClick={onClick}>
          voir plus
        </Link>
      )}

      {onClick && (
        <span className={style.seeMore} onClick={onClick}>
          voir plus
        </span>
      )}
    </p>
  );
};

export default LinkSeeMore;
