import type { FunctionComponent } from 'react';
import React, { Fragment } from 'react';

import Responsive from '../Responsive/Responsive';
import InfoPill from './components/InfoPill';
import style from './InfoList.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

export type InfoModel = {
  className?: string;
  itemClassName?: string;
  iconColor?: string;
  iconName: string;
  iconPosition?: string;
  iconSize: number;
  isToolTipOpen?: boolean;
  label: string | null;
  onBlur?: () => void;
  onClick?: () => void;
  phoneNumber?: string;
  revealValue?: boolean;
  toggleRevealValue?: (value: boolean) => void;
  type: string;
  url?: string;
  toolTipContent?: JSX.Element[];
};

type InfoListProps = {
  infoList: (
    | InfoModel
    | {
        mobile: InfoModel;
        desktop: InfoModel;
      }
  )[];
  dataTestId?: string;
  wrapperClassName?: string;
};

const InfoList: FunctionComponent<InfoListProps> = ({
  infoList,
  dataTestId,
  wrapperClassName,
}) => {
  return (
    <div
      data-testid={dataTestId}
      className={mergeClassNames([
        style.infoListItems,
        wrapperClassName?.length ? wrapperClassName : null,
      ])}
    >
      {infoList.map((item, index) =>
        'mobile' in item ? (
          <Fragment key={index}>
            <Responsive is="mobile">
              <InfoPill {...item.mobile} />
            </Responsive>
            <Responsive is="desktop">
              <InfoPill {...item.desktop} />
            </Responsive>
          </Fragment>
        ) : (
          <InfoPill {...item} key={index} />
        ),
      )}
    </div>
  );
};

export default InfoList;
