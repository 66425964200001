import type { FunctionComponent } from 'react';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import type {
  Breadcrumb as BreadcrumbModel,
  BannerModel,
  FeedbackStatsModel,
  Garage,
} from 'src/models';
import Carousel from 'src/packages/Carousel';
import { infoTypes } from 'src/pages/garage-auto/constants';

import {
  Icon,
  Image,
  InlineCritizr,
  Link,
  Title1,
} from 'src/components/shared';
import Breadcrumb from 'src/components/shared/Breadcrumb';
import type { IconType } from 'src/components/shared/Icon/types';
import InfoList from 'src/components/shared/InfoList';
import InfoPill from 'src/components/shared/InfoList/components/InfoPill';
import LightBox from 'src/components/shared/LightBox/LightBox';
import LinkSeeMore from 'src/components/shared/LinkSeeMore/LinkSeeMore';
import Promise from 'src/components/shared/Promise/Promise';
import Responsive from 'src/components/shared/Responsive/Responsive';
import { Col, Row } from 'src/components/ui';

import style from './GarageInfos.module.scss';

import { getGarage } from 'src/services/garage/getGarage';

import { formatDate } from 'src/utils/Date.Utils';

export type GarageInfosProps = {
  banners: BannerModel[];
  garage: Garage;
  slug: string | undefined;
  feedbackStats: FeedbackStatsModel;
  handleSeeMoreClick: () => void;
  breadcrumbItems: BreadcrumbModel[];
};

const EMPTY_VALUE = '';

const getGarageOpeningLabel = (
  isGarageOpen: boolean,
  startingAtAlias: string,
  startingTime: string,
  isClient: boolean,
  holidaysEndDate?: string,
) => {
  if (!isClient || !startingTime || !startingAtAlias) return null;

  if (holidaysEndDate) {
    return `Fermeture exceptionnelle`;
  }

  return isGarageOpen ? 'ouvert' : `ouvre ${startingAtAlias} à ${startingTime}`;
};

const GarageInfos: FunctionComponent<GarageInfosProps> = ({
  garage,
  slug,
  banners,
  feedbackStats,
  handleSeeMoreClick,
  breadcrumbItems,
}) => {
  const [showOpeningDays, setShowOpeningDays] = useState(false);
  const [revealPhoneNumber, setRevealPhoneNumber] = useState(false);
  const [isLightboxOpen, setIsLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isGarageOpen, setIsGarageOpen] = useState(false);
  const [startingAtAlias, setStartingAtAlias] = useState('');
  const [startingTime, setStartingTime] = useState('');
  const [isClient, setIsClient] = useState(false);

  const getImagesLength = () => garage?.homepagePictures.length;
  const getNextIndex = () => (photoIndex + 1) % getImagesLength();
  const getPrevIndex = () =>
    (photoIndex + garage.homepagePictures.length - 1) % getImagesLength();

  const gtmEvent = useCallback(
    (
      event: string,
      eventAction: string,
      extraParams?: Record<string, string>,
    ) => {
      TagManager.dataLayer({
        dataLayer: {
          event,
          eventAction,
          codeRepGarage: garage?.codeRepGarage,
          cpGarage: garage?.zipCode,
          enseigneGarage: garage?.typeEnseigne,
          raisonSocialeGarage: garage?.name,
          ...extraParams,
        },
      });
    },
    [garage],
  );

  useEffect(() => {
    gtmEvent('Leads Garage – Fiche Garage', EMPTY_VALUE, {
      pageName: 'home garage',
      pageCategory: 'generic',
      idClient: EMPTY_VALUE,
      cpClient: EMPTY_VALUE,
      civClient: EMPTY_VALUE,
      villeClient: EMPTY_VALUE,
    });
  }, [gtmEvent]);

  useEffect(() => {
    async function fetchAvailability() {
      const response = await getGarage(slug as string);
      setIsGarageOpen(response?.earliestOpening?.isOpen as boolean);
      setStartingAtAlias(response?.earliestOpening?.nameAlias as string);
      setStartingTime(response?.earliestOpening?.openSlot?.start as string);
    }

    fetchAvailability();
  }, [slug]);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const handlePhotoClick = () => {
    setIsLightBoxOpen(true);
    gtmEvent('Leads Garage – Photos Garage', 'Photos Garage');
  };

  const renderGarageServices = garage.services.slice(0, 9).map((service) => {
    return (
      <li
        className={style.garageService}
        key={service.id}
        title={service.label}
      >
        <Icon
          color={'white'}
          height={42}
          width={42}
          name={service.urlIcon as IconType}
          className={style.serviceIcon}
        />
      </li>
    );
  });

  const renderOpeningDays = garage?.openingDays.map((openingDay, index) => {
    const hours = openingDay.slots.map((hour, i) => {
      let and;

      if (i < 1) {
        and = 'et ';
      }

      return (
        <div key={i}>
          <span>
            {hour.start} - {hour.end} {and}
          </span>
        </div>
      );
    });

    return (
      <li className={style.garageOpeningDay} key={index}>
        <div className={style.dayName}>{openingDay.name}</div>
        <div className={style.openingHours}>{hours}</div>
      </li>
    );
  });

  const renderHolidaysText = garage.holidaysEndDate
    ? [
        <li className={style.holidaysText} key={'holidaysText'}>
          Etablissement exceptionnellement fermé jusqu’au{' '}
          {formatDate(garage.holidaysEndDate, 'DD/MM/YYYY')}
        </li>,
        <li className={style.communWorkingHours} key={'communWorkingHours'}>
          Horaires habituels :
        </li>,
      ]
    : [];

  const renderPromoBanner = banners.map((banner) => {
    return (
      <Fragment key={banner.id}>
        <Responsive isNot="mediumDesktop">
          <Link href={banner.url} className={style.promoLink}>
            <Image
              width={501}
              height={288}
              key={banner.id}
              src={banner.icon}
              layout="responsive"
              alt={banner.alt ?? 'bannière promo'}
            />
          </Link>
        </Responsive>
        <Responsive is="mediumDesktop">
          <Link href={banner.url} className={style.promoLink}>
            <Image
              width={325}
              height={201}
              key={banner.id}
              src={banner.icon}
              alt={banner.alt ?? 'bannière promo'}
            />
          </Link>
        </Responsive>
      </Fragment>
    );
  });

  const infoList = [
    {
      iconName: 'info-presta',
      iconSize: 30,
      label: getGarageOpeningLabel(
        isGarageOpen,
        startingAtAlias,
        startingTime,
        isClient,
        garage.holidaysEndDate,
      ),
      iconColor: 'white',
      type: infoTypes.AVAILABILITY,
      onClick: () => setShowOpeningDays((prev) => !prev),
      onBlur: () => setShowOpeningDays(false),
      itemClassName: garage.holidaysEndDate && style.holidaysBtn,
      className: isGarageOpen
        ? style.garageOpeningsBtn
        : style.garageOpeningsBtnClosed,
      isToolTipOpen: showOpeningDays,
      toolTipContent: [...renderHolidaysText, ...renderOpeningDays],
      tooltipClassName: style.tooltip,
    },
    {
      iconName: 'telephone',
      iconSize: 18,
      label: 'Téléphone',
      type: infoTypes.PHONE,
      phoneNumber: garage.phoneNumber,
      revealValue: revealPhoneNumber,
      toggleRevealValue: setRevealPhoneNumber,
      onClick: () =>
        gtmEvent('Leads Garage – Appel Garage', 'Telephone Garage'),
    },
    {
      iconName: 'y-aller',
      iconSize: 18,
      label: 'Plan',
      type: infoTypes.URL,
      url: `http://www.google.com/maps/place/${garage.location}`,
      onClick: () => gtmEvent('Leads Garage - Y aller', 'Plan Garage'),
    },
    {
      iconName: 'mail',
      iconSize: 18,
      label: 'Email',
      type: infoTypes.URL,
      url: `/garage/${slug}/contact`,
      onClick: () => gtmEvent('Leads Garage – E-mail Garage', 'Email Garage'),
    },
    {
      iconName: 'photos',
      iconSize: 18,
      label: 'Photos',
      type: infoTypes.URL,
      onClick: handlePhotoClick,
    },
  ];

  const renderGarageAddress = (
    <Link
      href={`http://www.google.com/maps/place/${garage.location}`}
      onClick={() => gtmEvent('Leads Garage - Y aller', 'Plan Garage')}
    >
      <div className={style.garageAddress}>{garage?.address}</div>
      <div className={style.garageCity}>
        {garage?.zipCode} {garage?.city}
      </div>
    </Link>
  );

  return (
    <div className={style.garageInfos} data-testid="garage_info">
      <div className={style.infosWrapper}>
        <div
          className={style.infosHeader}
          data-testid="garage_info_header_desktop"
        >
          <Breadcrumb
            className={style.breadcrumb}
            breadcrumbItems={breadcrumbItems}
            hasRichSnippet={false}
          />
          <h1 className={style.garageName} data-testid="garage_info_title">
            {garage.businessName
              ? `${garage.businessName} - ${garage.city} (${garage.zipCode})`
              : garage.name}
          </h1>
          <Row className={style.headerWrapper}>
            <Col md={8}>
              <LinkSeeMore
                text={garage?.companyPresentation}
                maxCharLength={190}
                onClick={handleSeeMoreClick}
                className={style.garageDescription}
                dataTestId="garage_info_description"
              />
            </Col>
            <Col md={4} className={style.mainInfos}>
              <strong>Adresse :</strong>
              <address data-testid="garage_info_address">
                {renderGarageAddress}
              </address>
            </Col>
          </Row>
          <InfoList dataTestId="garage_info_btns" infoList={infoList} />
          {isLightboxOpen && (
            <LightBox
              wrapperClassName={style.lightboxWrapper}
              mainSrc={garage?.homepagePictures[photoIndex]}
              onCloseRequest={() => setIsLightBoxOpen(false)}
              nextSrc={garage?.homepagePictures[getNextIndex()]}
              prevSrc={garage?.homepagePictures[getPrevIndex()]}
              onMovePrevRequest={() => setPhotoIndex(getPrevIndex())}
              onMoveNextRequest={() => setPhotoIndex(getNextIndex())}
            />
          )}
        </div>
        <div
          className={
            banners?.length > 0 ? style.carouselWrapper : style.promiseWrapper
          }
        >
          {banners?.length > 0 ? (
            <Carousel
              autoPlay
              autoPlayInterval={3000}
              hasArrows={false}
              hasPagination={false}
              customPaginationItemClass={style.paginationDotsItem}
              customPaginationContainer={style.paginationContainer}
              activeCustomPagination={style.paginationActiveItem}
              breakpoints={{
                mediumDesktop: {
                  hasPagination: true,
                  hasArrows: true,
                  prevArrow: (
                    <Icon
                      className={style.prevArrow}
                      name="chevron-g"
                      width={32}
                      height={32}
                    />
                  ),
                  nextArrow: (
                    <Icon
                      className={style.nextArrow}
                      name="chevron-d"
                      width={32}
                      height={32}
                    />
                  ),
                },
              }}
            >
              {renderPromoBanner}
            </Carousel>
          ) : (
            <Promise
              imageUrl={'/images/common/xavier.png'}
              title={"Ad s'engage"}
              text1={'prendre soin avec vous '}
              text2={'de votre véhicule'}
              isGarage
            />
          )}
          <div className={style.mobileBtns}>
            <InfoList infoList={infoList.slice(1, 4)} />
          </div>
        </div>
        <div
          className={style.mobileContainer}
          data-testid={'garage_info_header_mobile'}
        >
          <InlineCritizr
            txtRatingColor={'Brown'}
            starsPosition={'right'}
            className={style.garageFeedbacks}
            totalRatings={feedbackStats.countReview}
            averageRating={feedbackStats.reviewNote}
            actorId={garage.garIdNetwork}
          />
          <Title1
            className={style.garageNameMobile}
            data-testid="garage_info_title"
          >
            {garage.businessName
              ? `${garage.businessName} - ${garage.city} (${garage.zipCode})`
              : garage.name}
          </Title1>
          <address className={style.locationMobile}>
            {renderGarageAddress}
          </address>
          {garage?.earliestOpening && <InfoPill {...infoList[0]} />}
        </div>
        {garage.services.length > 0 && (
          <div className={style.garageServices}>
            <div className={style.servicesTitle}>
              Services disponibles dans ce garage
            </div>
            <ul className={style.servicesList}>{renderGarageServices}</ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default GarageInfos;
