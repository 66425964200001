import type { FunctionComponent } from 'react';
import React from 'react';
import { infoTypes } from 'src/pages/garage-auto/constants';

import { ButtonTertiary, Icon, Link } from 'src/components/shared';
import type { IconType } from 'src/components/shared/Icon/types';

import style from './InfoPill.module.scss';

import {
  generateDataTestId,
  getDataTestId,
  mergeClassNames,
} from 'src/utils/ReactUtils';

type InfoPillProps = {
  className?: string;
  itemClassName?: string;
  iconColor?: string;
  iconName: string;
  iconPosition?: string;
  iconSize: number;
  label: string | null;
  onBlur?: () => void;
  onClick?: () => void;
  phoneNumber?: string;
  revealValue?: boolean;
  toggleRevealValue?: (value: boolean) => void;
  type: string;
  url?: string;
  isToolTipOpen?: boolean;
  toolTipContent?: JSX.Element[];
  tooltipClassName?: string;
};

const InfoPill: FunctionComponent<InfoPillProps> = ({
  className,
  itemClassName,
  iconColor,
  iconName,
  iconSize,
  label,
  onBlur,
  onClick,
  phoneNumber,
  revealValue,
  toggleRevealValue,
  type,
  url,
  isToolTipOpen,
  toolTipContent,
  tooltipClassName,
}) => {
  if (!label) return null;

  return (
    <div
      onClick={() => {
        if (type === infoTypes.PHONE) {
          toggleRevealValue?.(true);
        }
        onClick?.();
      }}
      className={mergeClassNames([style.infoPillItem, itemClassName])}
      {...getDataTestId({ dataTestId: generateDataTestId(label ?? '') })}
    >
      <ButtonTertiary
        className={mergeClassNames([style.infoButton, className])}
        color={iconColor === 'white' ? 'Green' : 'Brown'}
        url={url}
        onBlur={onBlur}
      >
        <div
          className={mergeClassNames([
            style.infoButtonContent,
            type === infoTypes.AVAILABILITY && style.infoButtonContentReversed,
          ])}
        >
          {revealValue && phoneNumber ? (
            <address data-testid="phone_number">
              <Link className={style.phoneNumber} href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </Link>
            </address>
          ) : (
            <>
              <Icon
                height={iconSize}
                width={iconSize}
                name={iconName as IconType}
                color={iconColor}
                className={
                  iconSize > 18
                    ? style.infoButtonIconAdjust
                    : style.infoButtonIcon
                }
              />

              <span className={style.infoLabel}>{label}</span>
              {type === infoTypes.AVAILABILITY && toolTipContent && (
                <Icon
                  height={18}
                  width={18}
                  name={isToolTipOpen ? 'fleche-h' : 'fleche-b'}
                  color="white"
                  className={`${style.infoBtnIconLeft}`}
                />
              )}
            </>
          )}
        </div>
      </ButtonTertiary>
      {isToolTipOpen && (
        <ul className={mergeClassNames([style.infosTooltip, tooltipClassName])}>
          {toolTipContent}
        </ul>
      )}
    </div>
  );
};

export default InfoPill;
